import { Col, Row } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../redux/states";
import { StateKeys } from "../redux/states/main.state";
import { ResourceResponse } from "../services/api.response.service";
import { AppState, InfoLogger } from "../services/app.service";
import Main from "../redux/actions/main.action";
import ApiEndpoint from "../services/api.endpoint.service";
import { debounce } from "lodash"

function VideoPlayer() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const video = state[StateKeys.VIDEO];
  const audio = state[StateKeys.AUDIO];
  const videoRef = useRef<HTMLVideoElement>(null);
  const { content_id, id } = video.data;
  const debounceOnPause = debounce(() => {

    if (videoRef.current && videoRef.current.paused) {
      handleVideoState(AppState.PAUSING)
    }

  }, 500)

  const handleVideoPlaying = () => {
    dispatch(
      Main.togglePageState({
        stateKey: StateKeys.AUDIO,
        data: AppState.PAUSING,
      })
    );
    !!video.data.id && videoRef.current && videoRef.current.play();
    handleVideoState(AppState.PLAY);
  };

  const handleVideoPlay = (value: ResourceResponse) => {
    if (!video.data || (video.data as ResourceResponse).id !== value.id) {
      dispatch(
        Main.postPageObjectDataSuccess({
          stateKey: StateKeys.VIDEO,
          data: value,
          clear: true,
        })
      );
      dispatch(
        Main.togglePageState({
          stateKey: StateKeys.VIDEO,
          data: AppState.LOADING,
        })
      );
    } else {
      handleVideoState(AppState.PLAYING);
    }
  };

  const handleVideoPause = () => {
    !!video.data.id && videoRef.current && videoRef.current.pause();
    handleVideoState(AppState.PAUSED);
  };

  const handleVideoState = (value: AppState) =>
    dispatch(Main.togglePageState({ stateKey: StateKeys.VIDEO, data: value }));

  useEffect(() => {
    if (video.pageState === AppState.PLAYING) {
      handleVideoPlaying();
    }

    if (
      video.pageState === AppState.PAUSING ||
      audio.pageState === AppState.PLAYING
    ) {
      handleVideoPause();
    }
  }, [audio.pageState, video.pageState]);


  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) {
      return;
    }

    handleLoadedMetadata()


  }, [videoRef.current?.duration]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) {
      return;
    }

    // Add event listeners
    videoElement.addEventListener('timeupdate', handleTimeUpdate);


    // Cleanup listeners on unmount
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };

  }, [video.data.duration]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) {
      return;
    }
    const durationCountDown = video.data.durationCountDown

    InfoLogger('durationCountDown update', video.data.currentTime, durationCountDown)

    if (durationCountDown === 1) {
      InfoLogger('give point')
      givePoint()
    }
    const down = durationCountDown - 1

    dispatch(Main.postPageObjectDataSuccess({ stateKey: StateKeys.VIDEO, data: { durationCountDown: down } }));


  }, [video.data.currentTime]);

  const handleTimeUpdate = () => {
    if (!videoRef.current) {
      return;
    }
    const videoCurrentTime = Math.round(videoRef.current.currentTime)

    dispatch(Main.postPageObjectDataSuccess({ stateKey: StateKeys.VIDEO, data: { currentTime: videoCurrentTime } }));

  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      const duration = Math.round(videoRef.current.duration)
      dispatch(Main.postPageObjectDataSuccess({ stateKey: StateKeys.VIDEO, data: { duration, durationCountDown: Math.round(duration / 2) } }));

      InfoLogger('handleLoadedMetadata',videoRef.current.duration)
    }
  };

  const givePoint = () => {

    if (!!video.data?.content_id) {
      dispatch(
        Main.postPageData({
          stateKey: StateKeys.USER_POINTS,
          apiEndpoint: ApiEndpoint.POINTS,
          req: {
            where: "watch",
            content_id: video.data.content_id,
            resource_id: video.data.id,
            points: 1,
          },
        })
      );
    }
  }

  const onEndedPoint = () => {
    const duration = -Math.round(video.data.duration / 4)
    const durationCountDown = video.data.durationCountDown
    InfoLogger('onEndedPoint', durationCountDown, duration)
    if (durationCountDown <= duration) {
      InfoLogger('give point onEndedPoint')
      givePoint()
      dispatch(Main.postPageObjectDataSuccess({ stateKey: StateKeys.VIDEO, data: { durationCountDown: video.data.duration } }));
    }
  }

  return (
    <>
      {!!video.data.id && <video
        id={`video-${(video.data as ResourceResponse).id}`}
        ref={videoRef}
        className="w-100"
        autoPlay
        controls
        controlsList="nodownload"
        key={
          (
            video.data as ResourceResponse
          ).id
        }
        onPause={debounceOnPause}
        onPlay={() => {
          handleVideoState(
            AppState.PLAYING
          );
        }}
        onEnded={onEndedPoint}
      >
        <source
          src={
            (
              video.data as ResourceResponse
            ).url
          }
          type="video/mp4"
          title={
            (
              video.data as ResourceResponse
            ).description
          }
        />
      </video>}
    </>

  );
}

export default VideoPlayer;
