import { Col, Row } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../redux/states";
import { StateKeys } from "../redux/states/main.state";
import { ResourceResponse } from "../services/api.response.service";
import { AppState, InfoLogger } from "../services/app.service";
import Main from "../redux/actions/main.action";
import { CalculateUserMediaPoints } from "../utils/extraFunctions";
import ApiEndpoint from "../services/api.endpoint.service";
import {debounce} from "lodash"

function AudioPlayer() {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const video = state[StateKeys.VIDEO];
  const audio = state[StateKeys.AUDIO];
  const audioRef = useRef<HTMLAudioElement>(null);
  const { content_id, id } = audio.data;
  const debounceOnPause = debounce(()=>{

    if(audioRef.current && audioRef.current.paused){
      handleAudioState(AppState.PAUSING)
    }

  }, 500)

  const handleAudioPlaying = () => {
    dispatch(
      Main.togglePageState({
        stateKey: StateKeys.VIDEO,
        data: AppState.PAUSING,
      })
    );
    !!audio.data.id && audioRef.current && audioRef.current.play();
    handleAudioState(AppState.PLAY);
  };

  const handleAudioPause = () => {
    !!audio.data.id && audioRef.current && audioRef.current.pause();
    handleAudioState(AppState.PAUSED);
  };

  const handleAudioState = (value: AppState) =>
    dispatch(Main.togglePageState({ stateKey: StateKeys.AUDIO, data: value }));

  useEffect(() => {
    if (audio.pageState === AppState.PLAYING) {
      handleAudioPlaying();
    }

    if (
      audio.pageState === AppState.PAUSING ||
      video.pageState === AppState.PLAYING
    ) {
      handleAudioPause();
    }
  }, [audio.pageState, video.pageState]);


  useEffect(() => {
    const audioElement = audioRef.current;

    if (!audioElement) {
      return;
    }

    handleLoadedMetadata()


  }, [audioRef.current?.duration]);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (!audioElement) {
      return;
    }

    // Add event listeners
    audioElement.addEventListener('timeupdate', handleTimeUpdate);


    // Cleanup listeners on unmount
    return () => {
      audioElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
    
  }, [audio.data.duration]);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (!audioElement) {
      return;
    }
    const durationCountDown = audio.data.durationCountDown

    InfoLogger('durationCountDown update', audio.data.currentTime, durationCountDown)

    if(durationCountDown === 1){
      InfoLogger('give point')
      givePoint()
    }
    const down = durationCountDown - 1

    dispatch(Main.postPageObjectDataSuccess({ stateKey: StateKeys.AUDIO, data: {durationCountDown: down} }));
    
    
  }, [audio.data.currentTime]);

  const handleTimeUpdate = () => {
    if (!audioRef.current) {
      return;
    }
    const audioCurrentTime = Math.round(audioRef.current.currentTime)

    dispatch(Main.postPageObjectDataSuccess({ stateKey: StateKeys.AUDIO, data: {currentTime: audioCurrentTime} }));

  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      const duration = Math.round(audioRef.current.duration)
      dispatch(Main.postPageObjectDataSuccess({ stateKey: StateKeys.AUDIO, data: { duration, durationCountDown: Math.round(duration/2)} }));
      
      InfoLogger('handleLoadedMetadata',audioRef.current.duration)
    }
  };

  const givePoint = () =>{
    if (!!content_id) {
      dispatch(
        Main.postPageData({
          stateKey: StateKeys.USER_POINTS,
          apiEndpoint: ApiEndpoint.POINTS,
          req: { where: "listen", content_id, resource_id: id, points: 1 },
        })
      );
    }
  }

  const onEndedPoint = () => {
    const duration = -Math.round(audio.data.duration / 4)
    const durationCountDown = audio.data.durationCountDown
    InfoLogger('onEndedPoint',durationCountDown,duration)
    if(durationCountDown <= duration){
      InfoLogger('give point onEndedPoint')
      givePoint()
      dispatch(Main.postPageObjectDataSuccess({ stateKey: StateKeys.AUDIO, data: {durationCountDown: audio.data.duration} }));
    }
  }

  return (
    <Row>
      {!!audio.data.id && (
        <Col sm={12}>
          <audio
            id={`audio-${(audio.data as ResourceResponse).id}`}
            ref={audioRef}
            className="w-100"
            autoPlay
            controls
            controlsList="nodownload"
            key={(audio.data as ResourceResponse).id}
            onPause={debounceOnPause}
            onPlay={() => {
              handleAudioState(AppState.PLAYING);
              // CalculateUserMediaPoints(setGiveUserPoint, "#audio");
            }}
            onEnded={onEndedPoint}
          >
            <source
              src={(audio.data as ResourceResponse).url}
              type="audio/mp3"
              title={(audio.data as ResourceResponse).description}
            />
          </audio>
        </Col>
      )}
    </Row>
  );
}

export default AudioPlayer;
